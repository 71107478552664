<template>
    <div class="tab-content-container">
        <div class="">
            <p class="heading-type-1">Manage Address</p>
        </div>
        <div class="row manage-address">
            <div class="col-lg-6 mb-3 d-flex" v-for="(i, index) in items"
                 v-bind:key="index" @click="selected=index" :class="{active:index === selected}">
                <AddressCard :name="i.name" :address="i.address" edit="true"/>
            </div>

            <div class="col-lg-6 mb-3 d-flex">
                <a href="" class="card-type-1 d-flex w-100p">
                    <a class="col add-address-tag"><span class="add-item-tag">➕</span><b>Add a new
                        address</b></a>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import AddressCard from '../components/single-component/address-card';

export default {
    components: { AddressCard },
    metaInfo: {
        title: 'Manage Address'
    },
    name: 'Manage-address',
    data () {
        return {
            selected: 0,
            items: [
                {
                    name: 'AjilRaj',
                    address: 'Edu Power tower, Opposite to HP petrol pumb, Kannapuram Sree Krishna Temple, Gandhi nagar, Chettupuzha, Thrissur, kerela - 680 876'
                },
                {
                    name: 'AjilRaj',
                    address: 'Edu Power tower, Opposite to HP petrol pumb, Kannapuram Sree Krishna Temple, Gandhi nagar, Chettupuzha, Thrissur, kerela - 680 876'
                },
                {
                    name: 'AjilRaj-1',
                    address: 'Edu Power tower, Opposite to HP petrol pumb, Kannapuram Sree Krishna Temple, Gandhi nagar, Chettupuzha, Thrissur, kerela - 680 876'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
