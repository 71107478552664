import { render, staticRenderFns } from "./Manage-address.vue?vue&type=template&id=1b8bd9d7&scoped=true&"
import script from "./Manage-address.vue?vue&type=script&lang=js&"
export * from "./Manage-address.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8bd9d7",
  null
  
)

export default component.exports