<template>
    <section>
        <div class="container-fluid">
            <div class="row fl-j-c">
                <div class="col-12 col-lg-9 col-xl-8">
                    <div class="d-block mt-5">
                        <ProgressSection/>
                    </div>
                </div>
                <div class="col-12">
                    <p class="xeo-heading-1 col">Select Address</p>
                    <div class="row m-0">
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-lg-6 mb-4 d-flex" v-for="(i, index) in items"
                                     v-bind:key="index" @click="selected=index" :class="{active:index === selected}">
                                    <AddressCard :name="i.name" :address="i.address" edit="false"/>
                                </div>
                                <div class="col-lg-6 mb-4 d-flex w-100p">
                                    <a href="" class="card-type-1 d-flex w-100p">
                                        <a class="col add-address-tag">
                                            <span class="add-item-tag">➕</span>
                                            <b>Add a new address</b>
                                        </a>
                                    </a>
                                </div>
                            </div>
                            <p class="xeo-heading-1">Choose Delivery Speed</p>
                            <div class="row">
                                <div class="col-lg-6 mb-4">
                                    <a class="card-type-1 active">
                                        <p class="mb-0"><b>Normal Delivery</b></p>
                                        <p class="text-grey mb-0">Delivery by 24 November 2020</p>
                                    </a>
                                </div>
                                <div class="col-lg-6 mb-4">
                                    <a class="card-type-1">
                                        <p class="mb-0"><b>Fast Delivery</b></p>
                                        <p class="text-grey mb-0">Delivery by Saturday - 21 November 2020 </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 ">
                            <div class="card-type-2 text-center mb-4">
                                <p class="xeo-heading-3">Apply Coupon</p>
                                <div>
                                    <div class="d-flex input-type-3">
                                        <input class="form-control" type="text">
                                        <a @click="clickedOffer" class="inside-button">Apply</a>
                                    </div>
                                </div>
                                <a href="" class="container-2">
                                    <p class="card-box-4-bottom">
                                        <span class="pr-3">🌕</span>
                                        4500 Mini Coins
                                    </p>
                                    <a @click="clickedavailCoin" class="inside-button-2" href="#">use</a>
                                </a>

                            </div>
                            <div class="card-type-1 text-center mb-4">
                                <p class="xeo-heading-3">Price Details</p>
                                <div class="row m-0">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 d-flex fl-j-sb">
                                                <p class="mb-1">Items * 2</p>
                                                <p class="mb-1">₹56,249</p>
                                            </div>
                                            <div class="col-12 d-flex fl-j-sb">
                                                <p class="mb-1">Delivery Fee</p>
                                                <p class="mb-1">₹50</p>
                                            </div>
                                            <div class="col-12 d-flex fl-j-sb">
                                                <p class="mb-1 text-green">Voucher Discount</p>
                                                <p class="mb-1 text-green">₹3000</p>
                                            </div>
                                            <div class="col-12 d-flex fl-j-sb">
                                                <p class="mb-1 text-green">Coin Discount</p>
                                                <p class="mb-1 text-green">₹50</p>
                                            </div>
                                            <div class="col-12 d-flex fl-j-sb">
                                                <p class="mb-1 c-font-18"><b>Total Amount</b></p>
                                                <p class="mb-1 c-font-18"><b>₹53,149</b></p>
                                            </div>
                                        </div>
                                        <div class="row fl-j-c my-3">
                                            <div class="col-5">
                                                <router-link to="/order-placed/" class="lego-btn btn-block xeo-btn-type-1">Pay Now</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal dialog ref="offer" width="35r" class="fl-x-c custom-modal" :cancel-button="false" hideFooter>
            <div class="modal-body-coin text-center p-4">
                <section class="coupon-list">
                    <div class="xeo-heading-2 mb-4">
                        My Coupons
                    </div>

                    <a href="" class="coupon-list-details">
                        <div class="fl-a-c d-flex fl-j-c">
                            <img src="../../assets/image/robotic.png" class="" alt="#">
                        </div>
                        <span class="ml-4 mb-0 font-weight-bolder">Discount on Kids Toys</span>
                        <div class="coupon-list-discount">
                            <p class="c-font-20 font-weight-bold">25<span class="c-font-14">%</span></p>
                            <p class="coupon-list-discount-text">Discount</p>
                        </div>
                    </a>

                    <a href="" class="coupon-list-details">
                        <div class="fl-a-c d-flex fl-j-c">
                            <img src="../../assets/image/stationery.png" class="" alt="#">
                        </div>
                        <span class="ml-4 mb-0 font-weight-bolder">Discount on red color</span>
                        <div class="coupon-list-discount">
                            <p class="c-font-20 font-weight-bold">27<span class="c-font-14">%</span></p>
                            <p class="coupon-list-discount-text">Discount</p>
                        </div>
                    </a>

                    <a href="" class="coupon-list-details">
                        <div class="fl-a-c d-flex fl-j-c">
                            <img src="../../assets/image/stationery.png" class="" alt="#">
                        </div>
                        <span class="ml-4 mb-0 font-weight-bolder">Discount on red color</span>
                        <div class="coupon-list-discount">
                            <p class="c-font-20 font-weight-bold">25<span class="c-font-14">%</span></p>
                            <p class="coupon-list-discount-text">Discount</p>
                        </div>
                    </a>

                    <div class="row fl-j-c mt-4">
                        <div class="col-4">
                            <button class="lego-btn btn-block xeo-btn-type-2">Apply</button>
                        </div>
                    </div>

                </section>
            </div>
        </modal>

        <modal dialog ref="availCoin" width="30r" class="fl-x-c custom-modal" :cancel-button="false" hideFooter>
            <div class="modal-body-coin text-center p-4">
                <p class="xeo-heading-1">Use Minibells Coins to Avail Discounts</p>
                <img class="coin-img" src="../../assets/image/coin.png" alt="...">
                <div class="row fl-j-c">
                    <div class="col-6">
                        <p class="mt-3 mb-1"><b>You have 4,500 Coins</b></p>
                        <p class="coin-p-tag-2">500 coins is worth ₹10</p>
                        <div class="row fl-j-c mt-4">
                            <div class="col-8">
                                <a @click="clickeduseCoin" class="lego-btn btn-block xeo-btn-type-2">Use</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal dialog ref="useCoin" width="30r" class="fl-x-c custom-modal" :cancel-button="false" hideFooter>
            <div class="modal-body-coin text-center p-4">
                <p class="xeo-heading-2">Use Minibells Coin</p>
                <img class="coin-img" src="../../assets/image/coin-trial.png" alt="#">
                <div class="row fl-j-c">
                    <div class="col-6">
                        <p class="mb-1 mt-3"><b>You have 4,500 Coins</b></p>
                        <p class="mb-0">500 coins is worth ₹10</p>
                        <div class="input-type-9 number">
                            <span class="adder-minus">-</span>
                            <input class="adder-input" type="text" value="1"/>
                            <span class="adder-plus">+</span>
                        </div>
                        <p class="c-font-22">
                            <b>Discount:
                                <span class="text-primary">
                                    <span class="c-font-14">₹</span>
                                    10
                                </span>
                            </b>
                        </p>
                        <div class="row fl-j-c">
                            <div class="col-8">
                                <button class="lego-btn btn-block xeo-btn-type-2">Use</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

    </section>
</template>

<script>
import ProgressSection from '../components/Progress-section';
import AddressCard from '../components/single-component/address-card';

export default {
    components: {
        ProgressSection,
        AddressCard
    },
    metaInfo: {
        title: 'Authentication'
    },
    name: 'Delivery-Option',
    data () {
        return {
            selected: 0,
            items: [
                {
                    name: 'AjilRaj',
                    address: 'Edu Power tower, Opposite to HP petrol pumb, Kannapuram Sree Krishna Temple, Gandhi nagar, Chettupuzha, Thrissur, kerela - 680 876'
                },
                {
                    name: 'AjilRaj',
                    address: 'Edu Power tower, Opposite to HP petrol pumb, Kannapuram Sree Krishna Temple, Gandhi nagar, Chettupuzha, Thrissur, kerela - 680 876'
                },
                {
                    name: 'AjilRaj-1',
                    address: 'Edu Power tower, Opposite to HP petrol pumb, Kannapuram Sree Krishna Temple, Gandhi nagar, Chettupuzha, Thrissur, kerela - 680 876'
                }
            ]
        };
    },
    mounted () {
        this.clickedOffer();
    },
    methods: {
        clickedOffer () {
            this.$refs.offer.show();
        },
        clickedavailCoin () {
            this.$refs.availCoin.show();
        },
        clickeduseCoin () {
            this.$refs.availCoin.close();
            this.$refs.useCoin.show();
        }
    }
};
</script>

<style scoped>
.coin-img {
    width: 90px;
}
</style>
