<template>
    <div>
        <div id="login-modal" class="login-modal">
            <div class="overflow-hidden">
                <div class="m-0 row">
                    <div class="modal-body-right col-lg-6 order-lg-last">
                        <div class="modal-right-component">
                            <p class="Modal-welcome">Welcome to</p>
                            <img class="modal-logo img-fluid" src="../../assets/image/logo.png">
                            <br>
                            <a class="text-white font-weight-bolder">New here ?</a>
                            <br>
                            <div class="row fl-j-c mt-3">
                                <div class="col-10">
                                    <a @click="ClickedItem('signUp')" class="lego-btn btn-block xeo-btn-type-4">Signup</a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-6 p-4">
                        <div class="m-2">
                            <p class="xeo-heading-4 text-primary text-center">Log In</p>
                            <form>
                                <div class="form-comp">
                                    <div class="form-group input-type-1">
                                        <label>Phone Number</label>
                                        <input class="form-control" placeholder="Enter Phone Number">
                                    </div>
                                    <div class="form-group input-type-1">
                                        <label for="exampleInputPassword1">Password</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1"
                                               placeholder="Enter Password">
                                    </div>
                                </div>
                                <div class="row fl-j-c mt-5">
                                    <div class="col-6">
                                        <a class="lego-btn btn-block xeo-btn-type-4">send OTP</a>
                                    </div>
                                    <div class="col-6">
                                        <a @click="ClickedItem('login')" class="lego-btn btn-block xeo-btn-type-1">Login</a>
                                    </div>

                                </div>
                                <small id="ForgotPassword" class="form-text text-center mt-4 text-grey d-block">
                                    <a @click="ClickedItem('forgotPassword')">
                                        Forgot Password ?
                                    </a>
                                </small>

                            </form>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>

</template>

<script>

export default {
    name: 'Login-modal',
    components: {},
    methods: {

        ClickedItem (item) {
            if (item === 'signUp') {
                this.$emit('clicked', 'signUp');
            } else if (item === 'login') {
                this.$emit('clicked', 'login');
            } else if (item === 'forgotPassword') {
                this.$emit('clicked', 'forgotPassword');
            }
        }
    }
};
</script>

<style scoped>

</style>
