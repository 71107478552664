<template>
    <div>
        <div id="login-modal" class="login-modal">
            <div class="overflow-hidden">
                <div class="m-0 row">
                    <div class="modal-body-right col-lg-6 order-lg-last">
                        <div class="modal-right-component">
                            <p class="Modal-welcome">Welcome to</p>
                            <img class="modal-logo img-fluid" src="../../assets/image/logo.png">
                        </div>
                    </div>
                    <div class="col-lg-6 p-4">
                        <div class="m-2">
                            <p class="xeo-heading-4 text-primary text-center">Log In</p>
                            <form>
                                <div class="form-comp">
                                    <div class="form-group input-type-1">
                                        <label>New Password</label>
                                        <input class="form-control" placeholder="Enter New Password">
                                    </div>
                                    <div class="form-group input-type-1">
                                        <label>Confirm Password</label>
                                        <input type="password" class="form-control"
                                               placeholder="Enter Password">
                                    </div>
                                </div>
                                <div class="row fl-j-c mt-5">
                                    <div class="col-6">
                                        <a class="lego-btn btn-block xeo-btn-type-4">send OTP</a>
                                    </div>
                                    <div class="col-6">
                                        <a class="lego-btn btn-block xeo-btn-type-1">Login</a>
                                    </div>

                                </div>
                            </form>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>
</template>

<script>

export default {
    name: 'Password-Change-modal'
};
</script>

<style scoped>

</style>
