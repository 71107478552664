<template>
    <div>
        <div class="signup-modal">
            <div class="">
                <div class="m-0 row py-3">
                    <div class="col-12">
                        <div class="modal-header-logo text-center">
                            <img alt="..." class="modal-logo-signup" src="../../assets/image/logo2.png">
                        </div>
                        <p class="xeo-heading-4 text-primary text-center"><strong>Sign Up</strong></p>
                        <form>
                            <div class="form-group input-type-1">
                                <label>Your Name</label>
                                <input aria-describedby="Help" aria-label=""
                                       class="form-control font-weight-light" placeholder="Enter Full Name">
                            </div>
                            <div class="form-group input-type-1">
                                <label>Mobile Number</label>
                                <div class="row">
                                    <div class="col-3 pr-0">
                                        <select class="select-dropdown form-control">
                                            <option>+91</option>
                                            <option>+44</option>
                                            <option>+016</option>
                                            <option>+98</option>
                                            <option>+67</option>
                                        </select>
                                    </div>
                                    <div class="col-9">
                                        <input aria-describedby="Help" class="form-control font-weight-light"
                                               placeholder="Enter Phone Number" type="tel">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group input-type-1">
                                <label>Email ID</label>
                                <input aria-describedby="Help" class="form-control font-weight-light"
                                       placeholder="Enter your Email" type="email">
                            </div>
                            <div class="form-group input-type-1">
                                <label for="exampleInputPassword1">Password</label>
                                <input class="form-control font-weight-light" id="exampleInputPassword1"
                                       placeholder="Enter Password"
                                       type="password">
                            </div>
                            <div class="row fl-j-c mt-4">
                                <div class="col-6">
                                    <button class="lego-btn btn-block xeo-btn-type-1" type="submit">Log In</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Signup-modal'
};
</script>

<style scoped>

</style>
