<template>
    <div class="custom-tab">
        <i :class="icon"></i>
        <span class="ml-3" v-if="heading" v-html="heading">My Coupons</span>
    </div>
</template>

<script>
export default {
    name: 'tab-item-head',
    props: {
        icon: {
            type: String,
            required: false,
            default: ''
        },
        heading: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">
.custom-tab {
    min-height: 90px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 4px 1px #9f9f9f47;
    margin-bottom: 20px;
    font-size: 1.1em;
    padding: 0.5rem 1rem;
    border: 1.5px solid transparent;

    &.active {
    cursor: pointer;
        color: #000;
        background-color: white;
        border: 1.5px solid #eb0061;
    }

    i {
        color: #fecf3c;
        font-size: 1.3em;
    }
}
</style>
