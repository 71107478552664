<template>
    <div class="product-box-container m-1">
        <div class="product-box-img">
            <img class="img-fluid" src="../../../assets/image/products/similar-1.jpeg"
                 alt="">
            <p class="pd-offer-text">
                44%<br>off
            </p>
        </div>
        <div class="product-box-details">
            <p class="pd-box-head-text">OnePlus 8 Pro</p>
            <p class="pd-box-sub-text">Onyx Black | 12GB RAM | 256GB STORAGE</p>
            <div class="d-flex fl-j-sb">
                <div class="pd-rate-amount-cont">
                    <div class="pd-rating-cont">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span>&nbsp;4.8</span>
                    </div>
                    <p class="m-0 font-weight-bold">
                        <span class="text-green">
                            <span class="product-rupee">&#8377; </span>
                            4,000
                        </span>
                        &nbsp;
                        <span class="text-light-grey "><s><span
                            class="product-rupee">&#8377; </span>4,000</s></span>
                    </p>
                </div>
                <div class="d-flex fl-a-e">
                    <a class="pd-add-cart" href="#">
                        <img class="img-fluid" src="../../../assets/image/nav/cart.png"
                             alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'product-card'
};
</script>

<style scoped lang="scss">

.product-box-container {
    border-radius: 8px;
    box-shadow: 0 0 6px 0px #a0a0a0b3;
    overflow: hidden;

    .product-box-img {
        height: 150px;
        padding: 25px 15px;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        box-shadow: 0px 2px 4px 0px #dededeba;
        position: relative;

        img {
            width: auto !important;
            max-width: 100%;
            max-height: 100%;
        }

        .pd-offer-text {
            position: absolute;
            top: 15px;
            left: 15px;
            min-width: 32px;
            line-height: 16px;
            text-align: center;
            margin-bottom: 0 !important;
            background-color: #eb0061;
            color: white;
            padding: 3px;
            border-radius: 6px;
            font-size: 0.8em;
        }

    }

    @media (min-width: 768px) {

        .product-box-img {
            height: 180px;
        }

    }

    .product-box-details {
        padding: 15px;

        .pd-box-head-text {
            font-size: 0.9em;
            margin: 0;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .pd-box-sub-text {
            font-size: 0.77em;
            color: #848484;
            margin-bottom: 5px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .pd-rating-cont {
            font-size: 0.9em;
            /*margin-bottom: 5px;*/
            color: #a0a0a0;
        }

        .pd-add-cart {
            display: flex;
            align-items: center;
            width: 30px;
            height: 30px;
            background: #ffcc3f;
            padding: 7px;
            border-radius: 5px;
            /*position: absolute;*/
            /*bottom: 0;*/
            /*right: 0;*/
        }

        @media (min-width: 768px) {

            .pd-add-cart {
                width: 40px;
                height: 40px;
            }
        }
    }

    @media (min-width: 1200px) {
        .product-box-img {
            height: 220px;
        }
    }
}
</style>
