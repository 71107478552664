<template>
    <div>
        <section>

            <div class="container-fluid">
                <div class="row">
                    <div class="col ">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Men</a></li>
                            <li class="breadcrumb-item">T-Shirt New York</li>
                        </ol>
                    </div>
                </div>

                <div class="row align-items-start">
                    <div class="col-lg-5 position-lg-sticky product-pos pb-5">
                        <div class="row m-0">

                            <div class="col-12 col-lg-10">
                                <div class="product-thumb-preview">
                                    <img class="fit-contain" src="../assets/image/products/mob-1.jpeg" alt="">
                                </div>
                                <div class="product-thumb-btn d-none d-lg-flex">
                                    <div class="col pr-2">
                                        <button class="button-1 lego-btn">Buy Now</button>
                                    </div>
                                    <div class="col pl-2">
                                        <button class="button-1 btn">Add To Cart</button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-lg-2 order-lg-first mt-4 mt-lg-0">
                                <ul class="product-list list-unstyled mb-3">
                                    <li class="product-thumb">
                                        <a class="" href="#">
                                            <img src="../assets/image/products/mob-1.jpeg" alt="#" class="fit-contain">
                                        </a>
                                    </li>
                                    <li class="product-thumb">
                                        <a class="" href="#">
                                            <img src="../assets/image/products/mob-2.jpeg" alt="#" class="fit-contain">
                                        </a>
                                    </li>
                                    <li class="product-thumb">
                                        <a class="" href="#">
                                            <img src="../assets/image/products/mob-3.jpeg" alt="#" class="fit-contain">
                                        </a>
                                    </li>
                                    <li class="product-thumb">
                                        <a class="" href="#">
                                            <img src="../assets/image/products/mob-4.jpeg" alt="#" class="fit-contain">
                                        </a>
                                    </li>
                                    <li class="product-thumb">
                                        <a class="" href="#">
                                            <img src="../assets/image/products/mob-5.jpeg" alt="#" class="fit-contain">
                                        </a>
                                    </li>
                                    <li class="product-thumb">
                                        <a class="" href="#">
                                            <img src="../assets/image/products/mob-1.jpeg" alt="#" class="fit-contain">
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="col-12 d-lg-none">
                                <div class="product-thumb-btn">
                                    <div class="col pr-2">
                                        <button class="button-1 btn">Buy Now</button>
                                    </div>
                                    <div class="col pl-2">
                                        <button class="button-1 btn">Add To Cart</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-7 font-weight-light pb-5">
                        <p class="product-name mb-0 text-black font-weight-normal">OnePlus 8 Pro <span>(Onyx Black | 12GB RAM | 256GB STORAGE)</span>
                        </p>
                        <p class="product-type">Mobile & Accessories</p>
                        <div class="row m-0">
                            <div>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </div>
                            <span class="product-rating">
                        <p> 4.3 Rating | 53214 Reviews</p>
                    </span>
                        </div>
                        <div class="product-details mb-2">
                            <div class="row">
                                <div class="col-4 col-lg-2">M.R.P :</div>
                                <div class="col font-weight-normal"><s><span class="product-rupee">&#8377; </span>59,999</s>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 col-lg-2">Deal Price :</div>
                                <div class="col text-default font-weight-normal"><span
                                    class="product-rupee">&#8377; </span>59,999
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 col-lg-2">You Save :</div>
                                <div class="col font-weight-normal"><span class="product-rupee">&#8377; </span>4,000
                                </div>
                            </div>
                        </div>
                        <div class="product-delivery-details mb-2">
                            <div class="row">
                                <div class="col-4 col-lg-2">Deliver To :</div>
                                <div class="col font-weight-normal">Ajil - Eravu 680686 <span
                                    class="deliver-change-btn"><a
                                    href="#"> Change</a></span></div>
                            </div>
                            <div class="row">
                                <div class="col-4 col-lg-2">Delivery By :</div>
                                <div class="col font-weight-normal">Saturday, 07 November.</div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-default">Order within 8 hrs and 31 Min to get offer.</div>
                            </div>
                        </div>
                        <div class="color-box-container mb-2">
                            <div class="font-weight-normal mb-1">Colors</div>
                            <a class="color-select " href="#">
                                <div class="color-box color-1"></div>
                                <span class="color-text text-default">Onyx Black</span>
                            </a>
                            <a class="color-select active" href="#">
                                <div class="color-box color-2"></div>
                                <span class="color-text text-default">Onyx Black</span>
                            </a>
                            <a class="color-select" href="#">
                                <div class="color-box color-3"></div>
                                <span class="color-text text-default">Onyx Black</span>
                            </a>
                        </div>
                        <div class="variant-box-container mb-3">
                            <div class="font-weight-normal mb-1">Variants</div>
                            <a class="variant-select active" href="#">
                                8GB RAM | 128 Storage
                            </a>
                            <a class="variant-select " href="#">
                                6GB RAM | 64 Storage
                            </a>
                        </div>
                        <div class="features-box-container">
                            <div class="font-weight-normal mb-1">Key Features</div>
                            <ul class="feature-list">
                                <li>8 GB RAM | 128 GB ROM | Expandable Upto 1 TB</li>
                                <li>17.02 cm (6.7 inch) Quad HD+ Display</li>
                                <li>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</li>
                                <li>4500 mAh Lithium-ion Battery</li>
                                <li>No cost EMI starting from ₹5,556/month</li>
                                <li>8 GB RAM | 128 GB ROM | Expandable Upto 1 TB</li>
                                <li>17.02 cm (6.7 inch) Quad HD+ Display</li>
                                <li>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</li>
                                <li>4500 mAh Lithium-ion Battery</li>
                                <li>No cost EMI starting from ₹5,556/month</li>
                                <li>8 GB RAM | 128 GB ROM | Expandable Upto 1 TB</li>
                                <li>17.02 cm (6.7 inch) Quad HD+ Display</li>
                                <li>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</li>
                                <li>4500 mAh Lithium-ion Battery</li>
                                <li>No cost EMI starting from ₹5,556/month</li>
                                <li>8 GB RAM | 128 GB ROM | Expandable Upto 1 TB</li>
                                <li>17.02 cm (6.7 inch) Quad HD+ Display</li>
                                <li>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</li>
                                <li>4500 mAh Lithium-ion Battery</li>
                                <li>No cost EMI starting from ₹5,556/month</li>
                            </ul>
                        </div>
                        <div>
                            <p class="mb-0 font-weight-normal">Sold By <span class="text-default">Mobile Bazaar</span>
                            </p>
                            <a href="#">Click to View 4 Other Sellers</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="align-items-start">
            <scrollactive class="product-details-info position-sticky product-detais-pos"
                          :offset="100" :duration="800" bezier-easing-value=".5,0,.35,1">
                <div class="container">
                    <div class="row fl-x-cc pd-side-scroll">
                        <a href="#section1" class="scrollactive-item product-info-tab is-active">Product Information</a>
                        <a href="#section2" class=" scrollactive-item product-info-tab">Similar Product</a>
                        <a href="#section3" class="scrollactive-item product-info-tab">Reviews & Rating</a>
                    </div>
                </div>
                <div class="product-detail-bottom-line"></div>
            </scrollactive>
            <div class="container-fluid">

                <div class="row pt-lg-4" id="section1">
                    <div class="col px-4 product-info main">
                        <p class="heading-type-1 mt-4">Product Information</p>

                        <table class="table table-bordered">
                            <tr>
                                <th scope="row" style="width: 15%;">BODY</th>
                                <td style="width: 15%;">Mark</td>
                                <td style="width: 70%;">64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera Depth Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera Depth Camera</td>
                            </tr>
                            <tr>
                                <th scope="row">DISPLAY</th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera 0MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera 0MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row">PLATFORM</th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera 10MP | 10MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth CVGA Depth Camera amera | 10MP Front Camera</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | VGA Depth Camera 10MP Camera</td>
                            </tr>

                            <tr>
                                <th scope="row">MEMORY</th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera the Bird</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP 2 MP +12 M + VGA Depth Camera | 10MP Front Camera the Bird</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth 2 MP +12 M Camera | 10MP Front Camera the Bird</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front CameraC amera the Bird</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP Front Camera the Bird</td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Mark</td>
                                <td>64 MP + 12 MP +12 MP + VGA Depth Camera | 10MP the Bird</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="row pt-lg-4 c-overflow-hide" id="section2">
                    <div class="col similar-product main my-4">
                        <p class="heading-type-1 px-3">Similar Products</p>
                        <div class="similar-items-carousel owl-carousel owl-theme w-120-xs">
                            <swiper class="swiper home-slider" data-mousewheel="1" data-parallax="1"
                                    :options="swiperOption">

                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="col px-1 items">
                                        <ProductCard/>
                                    </div>
                                </swiper-slide>

                            </swiper>

                        </div>
                    </div>
                </div>

                <div class="row pt-lg-4 align-items-start" id="section3">
                    <div
                        class="col-lg-4 col-md-12 col-12 rating-container position-lg-sticky rating-container-pos">
                        <div class="row mx-lg-0 mt-4">
                            <p class="heading-type-1 px-3">Customer Ratings</p>
                            <div class="col-12 c-padding-r-80 mb-5">
                                <div class="d-flex fl-j-sb mt-4">
                                    <i class="fa fa-star star-icon"></i>
                                    <i class="fa fa-star star-icon"></i>
                                    <i class="fa fa-star star-icon"></i>
                                    <i class="fa fa-star star-icon"></i>
                                    <i class="fa fa-star star-icon-inactive"></i>
                                </div>
                                <p class="d-flex fl-j-sb rating-sub mt-1">4.5 out of 5<span>&emsp;|&emsp;</span>531
                                    Reviews</p>
                                <div class="d-flex mb-2 fl-j-sb fl-a-c">
                                    <label class="rating-tag">5 Star</label>
                                    <div class="mx-3 progress-bar-1 flex-grow-1 clearfix">
                                        <div style="width:90%" class="inner-bar"></div>
                                    </div>
                                    <p class="rating-tag-2">311</p>
                                </div>
                                <div class="d-flex mb-2 fl-j-sb fl-a-c">
                                    <label class="rating-tag">3 Star</label>
                                    <div class="mx-3 progress-bar-1 flex-grow-1 clearfix">
                                        <div style="width: 60%" class="inner-bar"></div>
                                    </div>
                                    <p class="rating-tag-2">74</p>
                                </div>
                                <div class="d-flex mb-2 fl-j-sb fl-a-c">
                                    <label class="rating-tag">4 Star</label>
                                    <div class="mx-3 progress-bar-1 flex-grow-1 clearfix">
                                        <div style="width:40%" class="inner-bar"></div>
                                    </div>
                                    <p class="rating-tag-2">91</p>
                                </div>
                                <div class="d-flex mb-2 fl-j-sb fl-a-c">
                                    <label class="rating-tag">2 Star</label>
                                    <div class="mx-3 progress-bar-1 flex-grow-1 clearfix">
                                        <div style="width:20%" class="inner-bar"></div>
                                    </div>
                                    <p class="rating-tag-2">16</p>
                                </div>
                                <div class="d-flex mb-2 fl-j-sb fl-a-c">
                                    <label class="rating-tag">1 Star</label>
                                    <div class="mx-3 progress-bar-1 flex-grow-1 clearfix">
                                        <div style="width:35%" class="inner-bar"></div>
                                    </div>
                                    <p class="rating-tag-2">24</p>
                                </div>

                            </div>
                            <div class="col-12">
                                <div>
                                    <p class="xeo-heading-1">Review the Product</p>
                                    <p class="text-style-4">Share your thoughts and opinions on the product </p>
                                </div>
                                <div class="row fl-x-cc">
                                    <div class="col-lg-9 mb-3">
                                        <a @click="clicked" class="lego-btn btn-lg c-btn xeo-btn-type-8">
                                            Write a review
                                            <img class="ml-2" alt="#" src="../assets/image/icons/rate.png"/>
                                        </a>
                                    </div>
                                    <!--                                    <b-modal class="loginModal" id="modal-center1" hide-footer hide-header centered-->
                                    <!--                                             title="Change Password">-->
                                    <!--                                        <div class="p-4 text-center">-->
                                    <!--                                            <button type="button" @click="$bvModal.hide('modal-center1')" class="close">-->
                                    <!--                                                ×-->
                                    <!--                                            </button>-->
                                    <!--                                            <h4 class="modal-title" id="exampleModalLongTitle"><b>Complete Your-->
                                    <!--                                                Profile</b></h4>-->
                                    <!--                                            <div class="modal-icon-container">-->
                                    <!--                                                <i class="fa fa-search"></i>-->
                                    <!--                                            </div>-->
                                    <!--                                            <p>Complete your profile for better offers</p>-->
                                    <!--                                            <div>-->
                                    <!--                                                <a class="modal-btn" href="">Use</a>-->
                                    <!--                                            </div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </b-modal>-->

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-12">
                        <div class="row mx-lg-0 mt-4">
                            <p class="heading-type-1 px-3">Customer Reviews</p>
                            <div class="col-12 pr-lg-5 mb-5 pt-4">

                                <div class="card-type-1 mb-4">
                                    <div class="row m-0">
                                        <div class="col-2 col-lg-1 pl-0">
                                            <img src="../assets/image/profile1.jpg" class="img-fluid img-circle" alt="#">
                                        </div>
                                        <div class="col pl-0 d-flex align-items-center">
                                            <div>
                                                <p class="m-0 font-weight-bold text-grey">Name</p>
                                                <div class="pd-rating-cont">
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star"></span>
                                                    <span class="fa fa-star"></span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab dolorum esse
                                            excepturi hic impedit incidunt inventore magnam nulla obcaecati pariatur
                                            pers.
                                        </div>
                                    </div>
                                </div>

                                <div class="card-type-1 mb-4">
                                    <div class="row m-0">
                                        <div class="col-2 col-lg-1 pl-0">
                                            <img src="../assets/image/profile2.jpg" class="img-fluid img-circle" alt="#">
                                        </div>
                                        <div class="col pl-0 d-flex align-items-center">
                                            <div>
                                                <p class="m-0 font-weight-bold text-grey">Name</p>
                                                <div class="pd-rating-cont">
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star"></span>
                                                    <span class="fa fa-star"></span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab dolorum esse
                                            excepturi hic impedit incidunt inventore magnam nulla obcaecati pariatur
                                            pers.
                                        </div>
                                    </div>
                                </div>

                                <div class="card-type-1 mb-4">
                                    <div class="row m-0">
                                        <div class="col-2 col-lg-1 pl-0">
                                            <img src="../assets/image/profile3.jpg" class="img-fluid img-circle" alt="#">
                                        </div>
                                        <div class="col pl-0 d-flex align-items-center">
                                            <div>
                                                <p class="m-0 font-weight-bold text-grey">Name</p>
                                                <div class="pd-rating-cont">
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star"></span>
                                                    <span class="fa fa-star"></span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab dolorum esse
                                            excepturi hic impedit incidunt inventore magnam nulla obcaecati pariatur
                                            pers.
                                        </div>
                                    </div>
                                </div>

                                <div class="card-type-1 mb-4">
                                    <div class="row m-0">
                                        <div class="col-2 col-lg-1 pl-0">
                                            <img src="../assets/image/profile1.jpg" class="img-fluid img-circle" alt="#">
                                        </div>
                                        <div class="col pl-0 d-flex align-items-center">
                                            <div>
                                                <p class="m-0 font-weight-bold text-grey">Name</p>
                                                <div class="pd-rating-cont">
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star"></span>
                                                    <span class="fa fa-star"></span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab dolorum esse
                                            excepturi hic impedit incidunt inventore magnam nulla obcaecati pariatur
                                            pers.
                                        </div>
                                    </div>
                                </div>

                                <div class="card-type-1 mb-4">
                                    <div class="row m-0">
                                        <div class="col-2 col-lg-1 pl-0">
                                            <img src="../assets/image/profile2.jpg" class="img-fluid img-circle" alt="#">
                                        </div>
                                        <div class="col pl-0 d-flex align-items-center">
                                            <div>
                                                <p class="m-0 font-weight-bold text-grey">Name</p>
                                                <div class="pd-rating-cont">
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star"></span>
                                                    <span class="fa fa-star"></span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab dolorum esse
                                            excepturi hic impedit incidunt inventore magnam nulla obcaecati pariatur
                                            pers.
                                        </div>
                                    </div>
                                </div>

                                <div class="card-type-1 mb-4">
                                    <div class="row m-0">
                                        <div class="col-2 col-lg-1 pl-0">
                                            <img src="../assets/image/profile3.jpg" class="img-fluid img-circle" alt="#">
                                        </div>
                                        <div class="col pl-0 d-flex align-items-center">
                                            <div>
                                                <p class="m-0 font-weight-bold text-grey">Name</p>
                                                <div class="pd-rating-cont">
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star checked"></span>
                                                    <span class="fa fa-star"></span>
                                                    <span class="fa fa-star"></span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab dolorum esse
                                            excepturi hic impedit incidunt inventore magnam nulla obcaecati pariatur
                                            pers.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <modal dialog ref="authenticationModal" width="50r" class="fl-x-c custom-modal-full" :cancel-button="false" hideFooter>
            <LoginModal @clicked="ClickedModals"/>
        </modal>
        <modal dialog ref="signUpModal" width="25r" class="fl-x-c custom-modal" :cancel-button="false" hideFooter>
            <SignupModal/>
        </modal>
        <modal dialog ref="authModal" width="50r" class="fl-x-c custom-modal-full" :cancel-button="false" hideFooter>
            <AuthenticationModal/>
        </modal>
        <modal dialog ref="forgotModal" width="50r" class="fl-x-c custom-modal-full" :cancel-button="false" hideFooter>
            <PasswordChangeModal/>
        </modal>
    </div>
</template>

<script>
// import img1 from '../assets/image/products/mob-1.jpeg';
// import img2 from '../assets/image/products/mob-2.jpeg';
// import img3 from '../assets/image/products/mob-3.jpeg';
// import img4 from '../assets/image/products/mob-4.jpeg';
// import img5 from '../assets/image/products/mob-5.jpeg';

import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ProductCard from './components/single-component/product-card';
import LoginModal from './components/Login-modal';
import SignupModal from './../views/components/Signup-modal';
import AuthenticationModal from './../views/components/Authentication-modal';
import PasswordChangeModal from './../views/components/Password-change-modal';

export default {
    metaInfo: {
        title: 'Product Details'
    },
    name: 'Productdetails',
    components: {
        LoginModal,
        ProductCard,
        Swiper,
        SwiperSlide,
        PasswordChangeModal,
        AuthenticationModal,
        SignupModal
    },
    data () {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: false,
                // autoplay: {
                //     delay: 8000,
                //     disableOnInteraction: false
                // },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1600: {
                        slidesPerView: 6
                    },
                    1400: {
                        slidesPerView: 5
                    },
                    1200: {
                        slidesPerView: 5
                    },
                    1024: {
                        slidesPerView: 4
                    },
                    768: {
                        slidesPerView: 3
                    },
                    640: {
                        slidesPerView: 2
                    },
                    320: {
                        slidesPerView: 2
                    }
                }
            }
        };
    },
    // methods: {
    //     onItemChanged (event, currentItem, lastActiveItem) {
    //         // here you have access to everything you need regarding that event
    //     }
    // },
    mounted () {
        this.openModal();
    },
    methods: {
        async openModal () {
            await this.$refs.authenticationModal.show();
        },
        ClickedModals (response) {
            if (response === 'signUp') {
                this.$refs.authenticationModal.close();
                this.$refs.signUpModal.show();
            } else if (response === 'login') {
                this.$refs.authenticationModal.close();
                this.$refs.authModal.show();
            } else if (response === 'forgotPassword') {
                this.$refs.authenticationModal.close();
                this.$refs.forgotModal.show();
            }
        }

    }
};
</script>

<style scoped>

</style>
