<template>
    <div class="home">

        <section>
            <!--    <div class="home-slider owl-carousel owl-theme">-->
            <swiper class="swiper home-slider" data-mousewheel="1" data-parallax="1"
                    :options="swiperOption">

                <swiper-slide>
                    <div class="items bg-1">
                        <div class="home-slider-container">
                            <div class="row">
                                <div class="align-content-center col-6 d-flex flex-wrap">
                                    <img class="img-fluid" src="../assets/image/home-carousel/watch.png" alt="">
                                </div>
                                <div class="align-content-center col-6 d-flex flex-wrap text-right">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="home-slider-text">Best offers on fossil <br> watches</p>
                                        </div>
                                        <div class="col-12">
                                            <router-link to="/filterpage/" class="home-slider-btn">View Offers</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="items bg-2">
                        <div class="home-slider-container">
                            <div class="row">
                                <div class="align-content-center col-6 d-flex flex-wrap">
                                    <img class="img-fluid" src="../assets/image/home-carousel/watch.png" alt="">
                                </div>
                                <div class="align-content-center col-6 d-flex flex-wrap text-right">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="home-slider-text">Best offers on fossil <br> watches</p>
                                        </div>
                                        <div class="col-12">
                                            <a class="home-slider-btn" href="">View Offers</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="items bg-3">
                        <div class="home-slider-container">
                            <div class="row">
                                <div class="align-content-center col-6 d-flex flex-wrap">
                                    <img class="img-fluid" src="../assets/image/home-carousel/watch.png" alt="">
                                </div>
                                <div class="align-content-center col-6 d-flex flex-wrap text-right">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="home-slider-text">Best offers on fossil <br> watches</p>
                                        </div>
                                        <div class="col-12">
                                            <a class="home-slider-btn">View Offers</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="items bg-4">
                        <div class="home-slider-container">
                            <div class="row">
                                <div class="align-content-center col-6 d-flex flex-wrap">
                                    <img class="img-fluid" src="../assets/image/home-carousel/watch.png" alt="">
                                </div>
                                <div class="align-content-center col-6 d-flex flex-wrap text-right">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="home-slider-text">Best offers on fossil <br> watches</p>
                                        </div>
                                        <div class="col-12">
                                            <a class="home-slider-btn" href="">View Offers</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>

        </section>

        <section>
            <div class="container-fluid p-0 p-lg-3">
                <div class="row m-0 mt-3 c-margin-t-md-30">
                    <div
                        class="col-12 col-md-6 c-margin-b-15 c-margin-b-md-30 position-relative text-white shop-now-top-wrap zoom-in">
                        <img class="img-fluid" src="../assets/image/shop-now/tab.png" alt="">
                        <div class="shop-now-top">
                            <p class="banner-text">iPad Devices</p>
                            <p class="sub-banner-text">The latest range of iPads</p>
                            <router-link to="/productdetails/" class="banner-btn">Shop Now ></router-link>
                        </div>
                    </div>
                    <div
                        class="col-12 col-md-6 c-margin-b-15 c-margin-b-md-30 position-relative text-white shop-now-top-wrap zoom-in">
                        <img class="img-fluid" src="../assets/image/shop-now/bag.png" alt="">
                        <div class="shop-now-top">
                            <p class="banner-text">Wildcarft Bags</p>
                            <p class="sub-banner-text">The Best offers on bags</p>
                            <a class="banner-btn" href="">Shop Now ></a>
                        </div>
                    </div>
                    <div
                        class="col-12 col-md-6 c-margin-b-15 c-margin-b-md-30 position-relative text-white shop-now-top-wrap zoom-in">
                        <img class="img-fluid" src="../assets/image/shop-now/print.png" alt="">
                        <div class="shop-now-top">
                            <p class="banner-text">HP Devices</p>
                            <p class="sub-banner-text">Best offers on HP Devices</p>
                            <a class="banner-btn" href="">Shop Now ></a>
                        </div>
                    </div>
                    <div
                        class="col-12 col-md-6 c-margin-b-15 c-margin-b-md-30 position-relative text-white shop-now-top-wrap zoom-in">
                        <img class="img-fluid" src="../assets/image/shop-now/watch.png" alt="">
                        <div class="shop-now-top">
                            <p class="banner-text">Titan watches</p>
                            <p class="sub-banner-text">The Latest Collection</p>
                            <a class="banner-btn" href="">Shop Now ></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container-fluid p-0 p-lg-3 bg-light-grey py-4">
                <p class="section-heading text-center col">Marketplace</p>
                <swiper class="swiper marketplace-carousel" data-mousewheel="1" data-parallax="1"
                        :options="swiperOption1">

                    <swiper-slide>
                        <div class="col text-center items">
                            <img class="img-fluid" src="../assets/image/shops/realme.png" alt="">
                            <p class="box-sub-text">Realme Stores</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="col text-center items">
                            <img class="img-fluid" src="../assets/image/shops/crossword.png" alt="">
                            <p class="box-sub-text">Crossword Books</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="col text-center items">
                            <img class="img-fluid" src="../assets/image/shops/realme.png" alt="">
                            <p class="box-sub-text">Realme Stores</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="col text-center items">
                            <img class="img-fluid" src="../assets/image/shops/crossword.png" alt="">
                            <p class="box-sub-text">Crossword Books</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="col text-center items">
                            <img class="img-fluid" src="../assets/image/shops/realme.png" alt="">
                            <p class="box-sub-text">Realme Stores</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="col text-center items">
                            <img class="img-fluid" src="../assets/image/shops/crossword.png" alt="">
                            <p class="box-sub-text">Crossword Books</p>
                        </div>
                    </swiper-slide>

                </swiper>
            </div>
        </section>

        <section>
            <div class="container-fluid p-0 p-lg-3 bg-grey py-4">
                <p class="section-heading text-center col">Top Brands</p>
                <div class="brands-carousel owl-carousel owl-theme">
                    <swiper class="swiper home-slider" data-mousewheel="1" data-parallax="1"
                            :options="swiperOption2">

                        <swiper-slide>
                            <div class="col text-center items">
                                <img class="img-fluid" src="../assets/image/top-brands/fun.png" alt="">
                                <p class="box-sub-text">Funskool</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="col text-center items">
                                <img class="img-fluid" src="../assets/image/top-brands/hp.png" alt="">
                                <p class="box-sub-text">hp</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="col text-center items">
                                <img class="img-fluid" src="../assets/image/top-brands/classmates.png" alt="">
                                <p class="box-sub-text">classmates</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="col text-center items">
                                <img class="img-fluid" src="../assets/image/top-brands/fun.png" alt="">
                                <p class="box-sub-text">Funskool</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="col text-center items">
                                <img class="img-fluid" src="../assets/image/top-brands/hp.png" alt="">
                                <p class="box-sub-text">hp</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="col text-center items">
                                <img class="img-fluid" src="../assets/image/top-brands/classmates.png" alt="">
                                <p class="box-sub-text">classmates</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="col text-center items">
                                <img class="img-fluid" src="../assets/image/top-brands/fun.png" alt="">
                                <p class="box-sub-text">Funskool</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="col text-center items">
                                <img class="img-fluid" src="../assets/image/top-brands/hp.png" alt="">
                                <p class="box-sub-text">hp</p>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="col text-center items">
                                <img class="img-fluid" src="../assets/image/top-brands/classmates.png" alt="">
                                <p class="box-sub-text">classmates</p>
                            </div>
                        </swiper-slide>
                    </swiper>

                </div>
            </div>
        </section>

        <section class="c-overflow-hide d-none d-lg-block">
            <div class="container-fluid p-0 p-lg-3 py-4  w-105">
                <swiper class="swiper offer-carousel" data-mousewheel="1" data-parallax="1"
                        :options="swiperOption3">

                    <swiper-slide>
                        <div class="col text-center items position-relative">
                            <img class="img-fluid" src="../assets/image/offers/brush.png" alt="">
                            <p class="offers-content-1">Best of Offers <br>on Kids Toys</p>
                            <a class="offers-btn-1" href="">Shop Now</a>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="col text-center items position-relative">
                            <img class="img-fluid" src="../assets/image/offers/paint.png" alt="">
                            <p class="offers-content-2">Best of Offers <br>on Kids Toys</p>
                            <a class="offers-btn-2" href="">Shop Now</a>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="col text-center items position-relative">
                            <img class="img-fluid" src="../assets/image/offers/brush.png" alt="">
                            <p class="offers-content-1">Best of Offers <br>on Kids Toys</p>
                            <a class="offers-btn-1" href="">Shop Now</a>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="col text-center items position-relative">
                            <img class="img-fluid" src="../assets/image/offers/paint.png" alt="">
                            <p class="offers-content-2">Best of Offers <br>on Kids Toys</p>
                            <a class="offers-btn-2" href="">Shop Now</a>
                        </div>
                    </swiper-slide>

                </swiper>

            </div>
        </section>

        <section class="d-lg-none">
            <div class="container-fluid p-0 p-lg-3 py-4">
                <div class="row m-0">
                    <div class="col-12 text-center position-relative mb-3">
                        <img class="img-fluid" src="../assets/image/offers/brush.png" alt="">
                        <p class="offers-content-1">Best of Offers <br>on Kids Toys</p>
                        <a class="offers-btn-1" href="">Shop Now</a>
                    </div>
                    <div class="col-12 text-center position-relative">
                        <img class="img-fluid" src="../assets/image/offers/paint.png" alt="">
                        <p class="offers-content-2">Best of Offers <br>on Kids Toys</p>
                        <a class="offers-btn-2" href="">Shop Now</a>
                    </div>
                </div>
            </div>
        </section>

        <section class="c-overflow-hide">
            <div class="container-fluid p-0 px-lg-3">
                <div class="row">
                    <div class="col-lg-6 bg-pink text-white py-4">
                        <p class="section-heading text-center col">Latest & Upcoming Offers</p>
                        <div class="row m-0">
                            <div class="col-6 box-radius">
                                <img class="img-fluid" src="../assets/image/offers/psp.png" alt="">
                                <p class="box-sub-text">Playstation 5 | Coming Soon Get Notified</p>
                            </div>
                            <div class="col-6 box-radius">
                                <img class="img-fluid" src="../assets/image/offers/mouse.png" alt="">
                                <p class="box-sub-text">Logitech X Shorud | Get it Now </p>
                            </div>
                            <div class="col-6 box-radius">
                                <img class="img-fluid" src="../assets/image/offers/catridge.png" alt="">
                                <p class="box-sub-text">Printer Cartridges | Best Buy Get Now</p>
                            </div>
                            <div class="col-6 box-radius">
                                <img class="img-fluid" src="../assets/image/offers/pencil.png" alt="">
                                <p class="box-sub-text">Drawing Pencils Set | Limited Offer</p>
                            </div>
                            <div class="col text-center">
                                <a href="#" class="text-white">View More ></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 bg-grey py-4">
                        <p class="section-heading text-center col">Latest & Upcoming Offers</p>
                        <div class="row m-0">
                            <div class="col-6 box-radius">
                                <img class="img-fluid" src="../assets/image/offers/psp.png" alt="">
                                <p class="box-sub-text">Playstation 5 | Coming Soon Get Notified</p>
                            </div>
                            <div class="col-6 box-radius">
                                <img class="img-fluid" src="../assets/image/offers/mouse.png" alt="">
                                <p class="mt-3">Logitech X Shorud | Get it Now </p>
                            </div>
                            <div class="col-6 box-radius">
                                <img class="img-fluid" src="../assets/image/offers/catridge.png" alt="">
                                <p class="mt-3">Printer Cartridges | Best Buy Get Now</p>
                            </div>
                            <div class="col-6 box-radius">
                                <img class="img-fluid" src="../assets/image/offers/pencil.png" alt="">
                                <p class="mt-3">Drawing Pencils Set | Limited Offer</p>
                            </div>
                            <div class="col text-center">
                                <a href="#" class="">View More ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container-fluid p-0 p-lg-3 bg-light-grey py-4">
                <p class="section-heading text-center col">Best Buy Under 999</p>
                <div class="row m-0">
                    <div class="col-6 col-lg mb-3 box-radius">
                        <img class="img-fluid" src="../assets/image/best-buy/cream.png" alt="#">
                    </div>
                    <div class="col-6 col-lg mb-3 box-radius">
                        <img class="img-fluid" src="../assets/image/best-buy/hdd.png" alt="#">
                    </div>
                    <div class="col-6 col-lg mb-3 box-radius">
                        <img class="img-fluid" src="../assets/image/best-buy/cream.png" alt="#">
                    </div>
                    <div class="col-6 col-lg mb-3 box-radius">
                        <img class="img-fluid" src="../assets/image/best-buy/hdd.png" alt="#">
                    </div>
                    <div class="col-6 col-lg mb-3 box-radius d-none d-lg-flex">
                        <img class="img-fluid" src="../assets/image/best-buy/cream.png" alt="#">
                    </div>
                    <div class="col-12 text-center">
                        <a href="#" class="">View More ></a>
                    </div>
                </div>
            </div>
        </section>

        <section class="c-overflow-hide">
            <div class="container-fluid p-0 pt-lg-3 bg-grey pt-4">
                <p class="section-heading text-center col d-inline-block">Latest & Upcoming Offers</p>
            </div>
            <div class="container-fluid p-0 pb-lg-3 bg-grey px-lg-3 w-120">

                <swiper class="swiper upcoming-carousel" data-mousewheel="1" data-parallax="1"
                        :options="swiperOption4">

                    <swiper-slide>
                        <div class="col box-radius">
                            <img class="img-fluid" src="../assets/image/offers/psp.png" alt="">
                            <p class="mt-3">Playstation 5 | Coming Soon Get Notified</p>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="col box-radius">
                            <img class="img-fluid" src="../assets/image/offers/psp.png" alt="">
                            <p class="mt-3">Playstation 5 | Coming Soon Get Notified</p>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="col box-radius">
                            <img class="img-fluid" src="../assets/image/offers/psp.png" alt="">
                            <p class="mt-3">Playstation 5 | Coming Soon Get Notified</p>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="col box-radius">
                            <img class="img-fluid" src="../assets/image/offers/psp.png" alt="">
                            <p class="mt-3">Playstation 5 | Coming Soon Get Notified</p>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="col box-radius">
                            <img class="img-fluid" src="../assets/image/offers/psp.png" alt="">
                            <p class="mt-3">Playstation 5 | Coming Soon Get Notified</p>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="col box-radius">
                            <img class="img-fluid" src="../assets/image/offers/psp.png" alt="">
                            <p class="mt-3">Playstation 5 | Coming Soon Get Notified</p>
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="col box-radius">
                            <img class="img-fluid" src="../assets/image/offers/psp.png" alt="">
                            <p class="mt-3">Playstation 5 | Coming Soon Get Notified</p>
                        </div>
                    </swiper-slide>

                </swiper>

            </div>
        </section>

<!--        <b-modal class="loginModal" id="modal-center1" hide-footer hide-header centered title="Change Password" visible>-->
<!--            <div class="p-4 text-center">-->
<!--                <button type="button" @click="$bvModal.hide('modal-center1')" class="close">×</button>-->
<!--                <h4 class="modal-title" id="exampleModalLongTitle"><b>Complete Your Profile</b></h4>-->
<!--                <div class="modal-icon-container">-->
<!--                    <i class="fa fa-search"></i>-->
<!--                </div>-->
<!--                <p>Complete your profile for better offers</p>-->
<!--                <div>-->
<!--                    <a class="modal-btn" href="">Use</a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </b-modal>-->
        <modal dialog ref="alert" width="30r" class="fl-x-c custom-modal" :cancel-button="false" hideFooter>
            <div class="p-4 text-center">
                <h4 class="modal-title"><b>Complete Your Profile</b></h4>
                <div class="modal-icon-container">
                    <i class="fa fa-search"></i>
                </div>
                <p>Complete your profile for better offers</p>
                <div>
                    <a class="modal-btn" href="">Use</a>
                </div>
            </div>
        </modal>
        <!--        <div class="modal fade" id="ModalCenter" tabindex="-1" role="dialog" aria-labelledby="CenterTitle"-->
        <!--             aria-hidden="true">-->
        <!--          -->
        <!--        </div>-->
    </div>

</template>

<script>

import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
    name: 'Home',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiperOption: {
                direction: 'horizontal',
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                mousewheel: false,
                parallax: true,
                centeredSlides: true,
                // autoplay: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1200: {
                        speed: 1500
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    }
                }
            },
            swiperOption1: {
                slidesPerView: 4,
                spaceBetween: 0,
                loop: true,
                speed: 1000,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                // autoplay: {
                //     delay: 2000,
                //     disableOnInteraction: false
                // },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    }
                }
            },
            swiperOption2: {
                slidesPerView: 4,
                spaceBetween: 0,
                loop: true,
                speed: 1000,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                // autoplay: {
                //     delay: 2000,
                //     disableOnInteraction: false
                // },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 6,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    }
                }
            },
            swiperOption3: {
                spaceBetween: 0,
                loop: true,
                speed: 1000,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                // autoplay: {
                //     delay: 2000,
                //     disableOnInteraction: false
                // },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    }
                }
            },
            swiperOption4: {
                spaceBetween: 0,
                loop: true,
                speed: 1000,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    }
                }
            }
        };
    },
    mounted () {
        this.openModal();
    },
    methods: {
        // clicked () {
        //     this.$refs.alert.show();
        // }
        async openModal () {
            await this.$refs.alert.show();
        }
    }
};

</script>
<style scoped lang="scss">

</style>
