<template>
    <section>
        <div class="container-fluid">
            <div class="row fl-j-c">
                <div class="col-12 col-lg-9 col-xl-8">
                    <div class="d-block mt-5">
                        <ProgressSection/>
                    </div>
                    <div class="card-type-1 p-4 p-lg-5">
                        <section class="login-section">
                            <div>
                                <p class="heading-type-1 text-primary">Login</p>
                                <p class="">Login now with your account now and place the order</p>
                            </div>

                            <div class="login-section-form">
                                <div class="form-group input-type-1">
                                    <label for="usr">Phone Number</label>
                                    <input type="text" class="form-control" placeholder="Enter Phone Number" id="usr"
                                           name="username">
                                </div>

                                <div class="form-group input-type-1">
                                    <label for="usr">Password</label>
                                    <input type="password" class="form-control" placeholder="Enter Password"
                                           name="username">
                                </div>

                                <div class="row fl-j-c">

                                    <div class="col-lg-4 mb-3 mb-lg-0">
                                        <button class="lego-btn xeo-btn-type-1 btn-block">Continue</button>
                                    </div>
                                    <div class="col-lg-4">
                                        <router-link to="/authentication/" class="lego-btn xeo-btn-type-1 btn-block">Send OTP</router-link>
                                    </div>

                                </div>

                            </div>

                        </section>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProgressSection from '../components/Progress-section';

export default {
    components: { ProgressSection },
    metaInfo: {
        title: 'Cart Login'
    },
    name: 'Cart-Login'
};
</script>

<style scoped>

</style>
