<template>
    <div class="tab-content-container">
        <div>
            <p class="heading-type-1">
                Personal Information
                <span class="p-info-tag">
                    <a href="#">Edit</a>
                </span>
            </p>
        </div>
        <form class=" form-group Personal-info-form">
            <div class="row">
                <div class="col-lg-9">
                    <div class="row ml-lg-0">
                        <div class="col-lg-6 mb-2 pl-lg-0 form-group input-type-1">
                            <label>Name</label>
                            <input placeholder="enter first name" class="form-control"
                                   type="text">
                        </div>
                        <div class="col-lg-6 mb-2 pl-lg-0 form-group input-type-1">
                            <label> &ensp;</label>
                            <input placeholder="enter last name" class="form-control"
                                   type="text">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-9">
                    <div class="row ml-lg-0">
                        <div class="col-lg-6 mb-2 pl-lg-0 form-group input-type-1">
                            <label>E-mail ID</label>
                            <input placeholder="enter e-mail" class="form-control"
                                   type="text">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-9">
                    <div class="row ml-lg-0">
                        <div class="col-lg-6 mb-2 pl-lg-0 form-group input-type-1">
                            <label>Mobile Number</label>
                            <input placeholder="enter mobile number" class="form-control"
                                   type="text">
                        </div>
                        <div class="col-lg-6 mb-2 pl-lg-0 form-group input-type-1">
                            <label>Alternate Number</label>
                            <input placeholder="enter alternate number" class="form-control"
                                   type="text">
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-lg-8 col-xl-6">
                <div class="row">
                    <div class="col-lg-6 mb-3">
                        <button class="lego-btn btn-block c-btn xeo-btn-type-2">Change Password</button>
                    </div>
                    <div class="col-lg-6 mb-3">
                        <button class="lego-btn btn-block c-btn xeo-btn-type-1">Done</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    metaInfo: {
        title: 'Personal-Information'
    },
    name: 'Personal-Information'
};
</script>

<style scoped>

</style>
