<template>
    <div class="m-4">
        <div>
            <p class="xeo-heading-1 ml-0 text-primary">Filter</p>
            <p><b>Price Range</b></p>
            <PriceRange class="mt-5 mb-4"/>
        </div>
        <div class="filter-form-check">
            <p><b>Rating</b></p>
            <form-checkbox label="4 & above" class="mb-0"></form-checkbox>
            <form-checkbox label="3 & above" class="mb-0"></form-checkbox>
            <form-checkbox label="2 & above" class="mb-0"></form-checkbox>
            <form-checkbox label="1 & above" class="mb-0"></form-checkbox>
        </div>
        <div class="filter-form-check">
            <p><b>Brands</b></p>
            <form-checkbox label="Samsung" class="mb-0"></form-checkbox>
            <form-checkbox label="Apple" class="mb-0"></form-checkbox>
            <form-checkbox label="One Plus" class="mb-0"></form-checkbox>
            <form-checkbox label="Redmi" class="mb-0"></form-checkbox>
            <form-checkbox label="Boat" class="mb-0"></form-checkbox>
            <form-checkbox label="Realme" class="mb-0"></form-checkbox>
            <form-checkbox label="Vivo" class="mb-0"></form-checkbox>
        </div>
        <div class="filter-form-check">
            <p><b>Discount</b></p>
            <form-checkbox label="Samsung" class="mb-0"></form-checkbox>
            <form-checkbox label="Apple" class="mb-0"></form-checkbox>
            <form-checkbox label="One Plus" class="mb-0"></form-checkbox>
            <form-checkbox label="Redmi" class="mb-0"></form-checkbox>
            <form-checkbox label="Boat" class="mb-0"></form-checkbox>
            <form-checkbox label="Realme" class="mb-0"></form-checkbox>
            <form-checkbox label="Vivo" class="mb-0"></form-checkbox>
        </div>
    </div>
</template>

<script>
import PriceRange from './single-component/price-range';

export default {
    name: 'filter-modal',
    components: { PriceRange }
};
</script>

<style scoped>

</style>
