<template>
    <div class="card-type-1 position-relative w-100p">
        <a v-if="edit ==='true'" class="box-btn-item1">
            <span class="box-imag-icon icon-delete"></span>
        </a>
        <a v-if="edit ==='true'" class="box-btn-item2">
            <span class="box-imag-icon icon-edit"></span>
        </a>
        <p class="box-head"><b v-if="name" v-html="name">AjilRaj</b></p>
        <p class="m-0 text-grey mr-6" v-if="address" v-html="address">Edu Power tower Opposite to HP petrol pumb
            Kannapuram Sree Krishna Temple, Gandhi nagar
            Chettupuzha, Thrissur, kerela
            680 876</p>
    </div>
</template>

<script>
export default {
    name: 'address-card',
    props: {
        name: {
            type: String,
            required: false,
            default: ''
        },
        address: {
            type: String,
            required: false,
            default: ''
        },
        edit: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">
.card-type-1 {
    border: 1px solid transparent;
    cursor: pointer;

    .box-btn-item1 {
        position: absolute;
        right: 5%;
    }

    .box-imag-icon {
        width: 9px;
        font-size: 0.9rem;
        color: #a3a3a3;
        cursor: pointer;

        &:hover {
            color: gray;
        }
    }

    .box-btn-item2 {
        position: absolute;
        right: 12%;
    }
}

.active {
    .card-type-1 {
        border: 1px solid var(--color-primary);
    }
}
</style>
