<template>
    <ul class="stepprogressbar mb-3">
        <li class="active">Step 1 <span></span></li>
        <li>Step 2 <span></span></li>
        <li>Step 3 <span></span></li>
        <li>Step 4 <span></span></li>
    </ul>
</template>

<script>
export default {
    name: 'progress-section'
};
</script>

<style scoped>

</style>
