<template>
    <div class="authentication-modal">
        <div class="overflow-hidden">
            <div class="m-0 row">
                <div class="modal-body-right col-lg-6 order-lg-last">
                    <div class="modal-right-component">
                        <img class="modal-logo" src="../../assets/image/smile.png">
                    </div>
                </div>
                <div class="col-lg-6 p-4">
                    <div class="m-2">
                        <p class="xeo-heading-4 text-primary text-center">Authentication</p>
                        <a>
                            <small class="form-text3">OTP has been send to your registered Mail &
                                number
                            </small>
                        </a>
                        <form>
                            <div class="form-comp">
                                <div class="form-group">
                                    <label>Enter OTP</label>
                                    <input aria-describedby="Help"
                                           class="form-control" placeholder="Enter the OTP">
                                </div>
                            </div>
                            <div class="row fl-j-c mb-3 mt-5">
                                <div class="col-6">
                                    <button class="lego-btn btn-block xeo-btn-type-1" type="submit">Login</button>
                                </div>
                            </div>
                            <div class="form-txt-comp fl-x-tc mt-auto">

                                <a>
                                    <small class="form-text1" id="Resend OTP">Resend OTP</small>
                                </a>
                                <p class="mx-2">|</p>
                                <a>
                                    <small class="form-text2" id="Change Numb">Change Number</small>
                                </a>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Authentication-modal'
};
</script>

<style scoped>

</style>
