<template>
    <div>
        <section>
            <div class="container-fluid p-0 p-lg-3">
                <div>
                    <div class="xeo-tab-container custom-tab">

                        <div class="container-fluid">
                            <div class="row fl-a-s">
                                <div class="col-12 d-md-none">
                                    <div class="custom-tab-head mt-4">
                                        <div class="row">
                                            <div class="pl-3">
                                                <div class="user-name-l"><b>A R</b></div>
                                            </div>
                                            <div class="col d-flex fl-a-c text-white pl-0">
                                                <div class="row text-left ml-0">
                                                    <div class="col-12"><b>Ajil Raj <a href=""></a></b></div>
                                                    <div class="col-12" style="text-transform: none">ajilraj@xeoscript.com</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mob-horiz-scroll-menu">
                                    <div class="custom-tab-head d-none d-md-flex">
                                        <div class="row">
                                            <div class="pl-3">
                                                <div class="user-name-l"><b>A R</b></div>
                                            </div>
                                            <div class="col d-flex fl-a-c text-white pl-0">
                                                <div class="row text-left ml-0">
                                                    <div class="col-12"><b>Ajil Raj <a href=""></a></b></div>
                                                    <div class="col-12" style="text-transform: none">ajilraj@xeoscript.com</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template>
                                        <div v-for="(i, index) in items" v-bind:key="index" @click="setSelected(index)" :ref="'head-' + index">
                                            <TabItemHead :icon="i.icon" :heading="i.heading" :class="{active:index === selected}"/>
                                        </div>
                                    </template>
                                </div>
                                <div class="col-md-9 mt-3 mt-md-0">
                                    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" appear duration="300">
                                        <PersonalInformation v-if="selected===0"/>
                                        <ManageAddress v-else-if="selected===1"/>
                                    </transition>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import TabItemHead from './components/single-component/tab-item-head';
import PersonalInformation from './My-account/Personal-Information';
import ManageAddress from './My-account/Manage-address';

export default {
    components: {
        ManageAddress,
        PersonalInformation,
        TabItemHead
    },
    data () {
        return {
            selected: 0,
            active_el: 0,
            items: [
                {
                    icon: 'icon-profile-fill',
                    heading: 'Personal Information'
                },
                {
                    icon: 'icon-home',
                    heading: 'Manage Address'
                },
                {
                    icon: 'icon-wallet',
                    heading: 'Payment Methods'
                },
                {
                    icon: 'fa fa-credit-card',
                    heading: 'My Coupons'
                },
                {
                    icon: 'icon-rating-1',
                    heading: 'My Reviews'
                },
                {
                    icon: 'icon-logout',
                    heading: 'Switch Account'
                }

            ]
        };
    },
    methods: {
        setSelected: function (index) {
            this.selected = index;
            let item = this.$refs['head-' + index];
            if (item) {
                item = item[0];
                const parent = item.parentElement;
                const rect = item.getBoundingClientRect();
                const pRect = parent.getBoundingClientRect();
                const left = item.offsetLeft - ((pRect.width - rect.width) / 2);
                parent.scrollTo({
                    left,
                    behavior: 'smooth'
                });
            }
        }
    },
    metaInfo: {
        title: 'My Account'
    },
    name: 'My-Account'

};
</script>

<style scoped lang="scss">
@media (max-width: 768px) {
    .mob-horiz-scroll-menu {
        display: flex;
        overflow: auto;
        position: sticky;
        top: 57px;
        background-color: #fff;
        z-index: 1;

        & > div {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        .custom-tab {
            margin-top: 15px;
            min-height: unset;
            margin-right: 0px;
        }

    }
}
</style>
