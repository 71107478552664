<template>
    <div>
        <section>
            <div class="container-fluid">
                <div class="row">
                    <div class="col ">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Men</a></li>
                            <li class="breadcrumb-item">T-Shirt New York</li>
                        </ol>
                    </div>
                </div>

                <div class="row m-0">
                    <div class="col-12 text-center b-1 fl-c-c p-2 mb-3 d-lg-none">
                        <p @click="clicked" class="xeo-heading-1 ml-0 text-primary mb-0"><span class="fa fa-filter mr-3"></span>Filter</p>
                    </div>
                    <div class="col-md-2 br-1 d-none d-lg-block">
                        <div>
                            <p class="xeo-heading-1 ml-0 text-primary">Filter</p>
                            <p><b>Price Range</b></p>
                            <PriceRange class="mt-5 mb-4"/>
                        </div>
                        <div class="filter-form-check">
                            <p><b>Rating</b></p>
                            <form-checkbox label="4 & above" class="mb-0"></form-checkbox>
                            <form-checkbox label="3 & above" class="mb-0"></form-checkbox>
                            <form-checkbox label="2 & above" class="mb-0"></form-checkbox>
                            <form-checkbox label="1 & above" class="mb-0"></form-checkbox>
                        </div>
                        <div class="filter-form-check">
                            <p><b>Brands</b></p>
                            <form-checkbox label="Samsung" class="mb-0"></form-checkbox>
                            <form-checkbox label="Apple" class="mb-0"></form-checkbox>
                            <form-checkbox label="One Plus" class="mb-0"></form-checkbox>
                            <form-checkbox label="Redmi" class="mb-0"></form-checkbox>
                            <form-checkbox label="Boat" class="mb-0"></form-checkbox>
                            <form-checkbox label="Realme" class="mb-0"></form-checkbox>
                            <form-checkbox label="Vivo" class="mb-0"></form-checkbox>
                        </div>
                        <div class="filter-form-check">
                            <p><b>Discount</b></p>
                            <form-checkbox label="Samsung" class="mb-0"></form-checkbox>
                            <form-checkbox label="Apple" class="mb-0"></form-checkbox>
                            <form-checkbox label="One Plus" class="mb-0"></form-checkbox>
                            <form-checkbox label="Redmi" class="mb-0"></form-checkbox>
                            <form-checkbox label="Boat" class="mb-0"></form-checkbox>
                            <form-checkbox label="Realme" class="mb-0"></form-checkbox>
                            <form-checkbox label="Vivo" class="mb-0"></form-checkbox>
                        </div>

                    </div>
                    <div class="col-lg-10">
                        <div class="row">
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>
                            <div class="col-6 col-md-4 col-xl-3 mb-3">
                                <ProductCard/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <modal dialog ref="filterModal" width="50r" class="custom-modal" :cancel-button="false" hideFooter>
            <FilterModal/>
        </modal>
    </div>
</template>

<script>
import PriceRange from './components/single-component/price-range';
import ProductCard from './components/single-component/product-card';
import FilterModal from './components/filter-modal';

export default {
    name: 'filterpage',
    components: { FilterModal, ProductCard, PriceRange },
    methods: {
        clicked () {
            this.$refs.filterModal.show();
        }
    }
};
</script>

<style lang="scss">
.filter-form-check {
    margin-bottom: 10px;

    .form-check {
        margin-bottom: 0;
        min-height: unset;
        padding: 0 0 4px 0;
    }
}
</style>
