<template>
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12">
                <div class="section-1 mb-5">
                    <div class="sub-section">
                        <div class="image-container">
                            <span class="icon-bag"></span>
                        </div>
                        <p class="sub-section-element-2">Your Order has been succesfully placed for <b><span
                            class="element-rupees">₹</span>53,149</b></p>
                        <div class="sub-section-element-container">
                            <p class="mr-1">🌕</p>
                            <p class="sub-section-element-3"> 200 Mini Coins Added</p>
                        </div>
                        <router-link to="/" class="btn lego-btn xeo-btn-type-1">Continue Shopping</router-link>
                    </div>
                </div>
                <div class="section-2">
                    <p class="xeo-heading-1">Your Items</p>
                    <div>
                        <div class="row">
                            <div class="col-md-6 mb-4">
                                <div class="product-container">
                                    <div class="img-element">
                                        <img class="product-container-img" src="../../assets/image/mob.jpeg">
                                    </div>
                                    <div class="prod-margin">
                                        <p class="mb-0 overflow-text"><b>One Plus 8</b></p>
                                        <p class="product-sub-title mb-2 overflow-text">Lunar Silver, 12 GB RAM, 256 GB ROM</p>
                                        <p class="prod-seller">Sold by<span class="text-primary">Mobile Bazar</span></p>
                                        <p class="product-del-date mb-0">Delivered by <span class="text-primary">24 nov 2020</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-4">
                                <div class="product-container">
                                    <div class="img-element">
                                        <img class="product-container-img" src="../../assets/image/mob.jpeg">
                                    </div>
                                    <div class="prod-margin">
                                        <p class="mb-0 overflow-text"><b>One Plus 8</b></p>
                                        <p class="product-sub-title mb-2 overflow-text">Lunar Silver, 12 GB RAM, 256 GB ROM</p>
                                        <p class="prod-seller">Sold by<span class="text-primary">Mobile Bazar</span></p>
                                        <p class="product-del-date mb-0">Delivered by <span class="text-primary">24 nov 2020</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-4">
                                <div class="product-container">
                                    <div class="img-element">
                                        <img class="product-container-img" src="../../assets/image/mob.jpeg">
                                    </div>
                                    <div class="prod-margin">
                                        <p class="mb-0 overflow-text"><b>One Plus 8</b></p>
                                        <p class="product-sub-title mb-2 overflow-text">Lunar Silver, 12 GB RAM, 256 GB ROM</p>
                                        <p class="prod-seller">Sold by<span class="text-primary">Mobile Bazar</span></p>
                                        <p class="product-del-date mb-0">Delivered by <span class="text-primary">24 nov 2020</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-3">
                    <div class="row">
                        <div class="col-lg-4">
                            <p class="text-primary"><b>Your Items will be delivered to</b></p>
                            <p>EduPower Tower, Opposite HP petrol pump<br>
                                Kannapuram Sree krishna Temple, gandhi Nagar<br>
                                Chettupuzha, Thrissur, Kerela<br>
                                680612</p>
                        </div>
                        <div class="col-lg-4 pl-lg-7">
                            <p class="text-primary"><b>Contact Number</b></p>
                            <p class="m-0">+91 9876543210</p>
                            <p class="m-0">991 9638527410</p>
                        </div>
                        <div class="col-lg-4 fl-x-c my-4 my-lg-0">
                            <button class="xeo-btn-type-8 lego-btn"><i class="fa fa-share-alt"></i>share Order</button>
                            <button class="xeo-btn-type-2 lego-btn ml-4"><i class="fa fa-close"></i>Cancel Order</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'order-placed'
};
</script>

<style scoped lang="scss">

.sub-section {
    margin: auto;
    text-align: center;
    padding-top: 50px;

    .image-container {
        border-radius: 50%;
        height: 115px;
        width: 115px;
        margin: auto;
        padding: 21px;
        background-color: #eb0061;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: white;
            font-size: 3rem;
        }
    }

    .sub-section-element-2 {
        margin-top: 15px;
        font-size: 21px;
        font-weight: 600;

        b {
            color: #eb0061;

            .element-rupees {
                font-size: 13px;
                font-weight: 400;
                margin-right: 5px;
            }

        }

    }

    .sub-section-element-container {
        display: flex;
        justify-content: center;

        .sub-section-element-3 {
            color: #eb0061a6;
            text-decoration: underline
        }
    }
}

.product-container {
    display: flex;

    .img-element {
        padding: 14px;
        box-sizing: border-box;
        height: 107px;
        width: 107px;
        border-radius: 8px;
        margin-left: 8px;
        box-shadow: 0 0 4px 0 #b7b7b7a3;

        .product-container-img {
            object-fit: contain;
            height: 100%;
            width: 100%;

        }
    }

    .prod-margin {
        margin-left: 1.3rem;

        .product-sub-title {
            font-size: 0.9rem;
        }

        .prod-seller {
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }

        .product-del-date {
            font-size: 11px;
            color: #565656e0;
        }
    }
}

</style>
